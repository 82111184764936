<template>
  <div>
    <div class="row align-items-center mb-4">
      <div class="col-md-6">
        <h2 class="text-dark d-inline-block m-0">Melior Available Reports</h2>
      </div>
      <div class="col-md-6">
        <div class="float-right"></div>
      </div>
    </div>

    <div>
      <SectionLoading v-if="loading" text="All Reports loading..." />
      <div v-else>
        <div
          v-if="contents && contents.data && contents.data.length == 0"
          class="text-center my-5"
        >
          <p class="text-dark mt-5 pt-5">No Reports available yet.</p>
        </div>
        <div v-else>
          <div class="card siteCard">
            <div class="table-responsive py-2">
              <vs-table
                id="div-with-loading"
                max-items="10"
                stripe
                :data="contents.data"
              >
                <template slot="thead">
                  <vs-th class="text-dark" scope="col">S/N</vs-th>
                  <vs-th class="text-dark" scope="col">Title</vs-th>
                  <vs-th class="text-dark" scope="col">Period</vs-th>
                  <vs-th class="text-dark" scope="col">Action</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(report, indextr) in data">
                    <td>
                      <p class="text-dark mb-0 small">{{ indextr + 1 }}</p>
                    </td>

                    <td>
                      <div class="font-weight-bold">
                        <p v-if="report.file" class="text-dark mb-0 title">
                          {{ report.file.name | capitalize }}
                        </p>
                      </div>
                    </td>

                    <td>
                      <div class="font-weight-bold">
                        <p v-if="report.file" class="text-dark mb-0 title">
                          {{ getFullMonth(report.file.month) | capitalize }} -
                          {{ report.file.year | capitalize }}
                        </p>
                      </div>
                    </td>

                    <td>
                      <a
                        :href="report.file.link || '#'"
                        target="_blank"
                        class="btn btn-primary btn-sm btn-site px-3"
                      >
                        Download
                      </a>
                    </td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SectionLoading from "../../../components/SectionLoading";

export default {
  data() {
    return {
      loading: false,
      contents: {
        data: [],
      },
    };
  },
  mounted() {
    this.getReport();
  },
  components: {
    SectionLoading,
  },
  methods: {
    getFullMonth(id) {
      switch (id) {
        case 1:
          return "January";
          break;
        case 2:
          return "February";
          break;
        case 3:
          return "March";
          break;
        case 4:
          return "April";
          break;
        case 5:
          return "May";
          break;
        case 6:
          return "June";
          break;
        case 7:
          return "July";
          break;
        case 8:
          return "August";
          break;
        case 9:
          return "September";
          break;
        case 10:
          return "October";
          break;
        case 11:
          return "November";
          break;
        case 12:
          return "December";
          break;
        default:
          return "January";
          break;
      }
    },
    getReport() {
      this.loading = true;

      let fetch = {
        path: `admin/earnings/statement`,
        data: {},
      };
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          this.contents = resp.data;
          this.$toast.success(
            "Melior Report has been successfully generated",
            "Melior Report Statement",
            this.$toastPosition
          );

          this.$vs.loading.close();
          this.loading = false;
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.loading = false;

          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Unable to download Statement",
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Something went wrong",
              "Unable to download Statement",
              this.$toastPosition
            );
          }
        });
    },
  },
};
</script>
